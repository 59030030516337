import CryptoJS from 'crypto-js';

interface Chunk {
  data: string
  offset: number
}

/* 讀取檔案塊 */
function readEachFileChunk(file: File, {
  chunkSize = 4 * 1024 * 1024, // 4 MB
  forEachChunk,
  onEnd
}: {
    chunkSize?: number
    forEachChunk: (chunk: Chunk) => void
    onEnd: (error?: any) => void
  }) {
  const fileSize = file.size;
  let offset = 0;

  const reader = new FileReader();
  const readNext = () => {
    const fileChunk = file.slice(offset, offset + chunkSize);
    reader.readAsBinaryString(fileChunk);
  };

  reader.onload = () => {
    if (reader.error) {
      onEnd(reader.error);
      return;
    }

    const chunk = reader.result as string;
    if (!chunk) {
      onEnd();
      return;
    }

    offset += chunk.length;
    // TODO: handle errors
    forEachChunk({
      data: chunk,
      offset
    });

    if (offset >= fileSize) {
      onEnd();
      return;
    }

    readNext();
  };

  reader.onerror = (error) => {
    onEnd(error);
  };

  readNext();
}

/** 計算當案 Hash */
export function createFileHash(
  file: File,
  onProgress?: (percentage: number) => void
): Promise<string> {
  return new Promise((resolve, reject) => {
    const md5 = CryptoJS.algo.MD5.create();

    readEachFileChunk(file, {
      forEachChunk({ data, offset }) {
        md5.update(CryptoJS.enc.Latin1.parse(data));

        const progress = Math.round(offset / file.size * 100);
        onProgress && onProgress(progress);
      },
      onEnd(err) {
        if (err) {
          reject(err);
          return;
        }

        // TODO: Handle errors
        const hash = md5.finalize().toString(CryptoJS.enc.Hex);
        resolve(hash);
      }
    });
  });
}
